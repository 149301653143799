<template>
    <a  href="#" class="dropdown-item"
        v-for="cita in siguientesCitas" :key="cita">
        <div @click.prevent="cargarExpediente(cita.id_servicio,cita)" data-toggle="tooltip" data-placement="top" style="overflow-x:auto; display: flex; gap: 10px; justify-content: space-between;">
            <span  style="width: 100%;" ><i :class="cita.class"></i>
            <i @click.stop="abrirform(cita)" style="margin-right: 5px;" title="Posponer" class="fab fa-product-hunt"></i>
            <span v-tooltip="extractContent(cita.direccion_cliente)" >{{cita.codigo_servicio}}</span>
            </span>
            <span style="width: 100%; text-align:center">{{ cita.fecha_hora }}</span>
            <span style="width: 100%; text-align:end">{{ cita.nombre_especialidad }}</span>
        </div>
    </a>
    <Dialog :style="{ width: '25rem' }" @hide="cerrarForm" header="Posponer cita" modal
        v-model:visible="formulario">
        <div class="row" style="justify-content: space-between; margin: 0;">
            <div style="display: grid;">
                <label for="icondisplay" class="font-bold block mb-2"> Fecha</label>
                <Calendar style="width: 150px;" v-model="fecha" iconDisplay="input" inputId="icondisplay" />
            </div>
            <div style="display: grid;">
                <label for="templatedisplay" class="font-bold block mb-2"> Hora </label>
                <Calendar style="width: 150px;" v-model="hora" iconDisplay="input" timeOnly inputId="templatedisplay"/>
            </div>
        </div>
        <div class="form-group" style="margin-top: 20px;">
            <label for="">Motivo</label>
            <textarea v-model="motivo" id="motivo" class="form-control" rows="3"></textarea>
        </div>
        <div style="display: flex; justify-content: end; gap: 15px;">
            <button @click="posponer" class="btn btn-sm btn-primary">Posponer</button>
            <button @click="cerrarForm" class="btn btn-sm btn-light">Cancelar</button>
        </div>
    </Dialog>
</template>
<script>
import Calendar from 'primevue/calendar';
import { PwgsApi } from '../../../../../services/PwgsApi';
export default({
    props:{
        siguientesCitas: Array
    },
    setup() {
        
    },
    components:{
        Calendar,
    },
    data(){
        return{
            formulario: false,
            citaselec: null,
            fecha:'',
            hora:'',
            motivo:'',
        }
    },
    methods: {
        cargarExpediente(id,cita){
            localStorage.setItem("tiposerv", 'cita');
            localStorage.setItem('nombreservicio' + id, cita.codigo_servicio);

            this.$router.push({
                name: 'Servicio',
                params: {
                    id:id
                }
            });
        },
        async posponer(){
            if(this.fecha =='' || this.hora =='' || this.motivo ==''){
                alert('Indique fecha, hora y motivo.');
                return;
            }
            const date = new Date(this.hora);
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const api = new PwgsApi();
            await api.put('avisos/posponer-siguiente-cita', {id_servicio:this.citaselec.id_servicio, fecha:this.fecha.toLocaleDateString(), hora:`${hours}:${minutes}`, motivo:this.motivo});
            this.$emit('actualizarCitas');
            this.cerrarForm();
        },
        abrirform(cita){
            this.citaselec = cita;
            this.formulario = true;
        },
        cerrarForm(){
            this.citaselec = null;
            this.formulario = false;
            this.fecha='';
            this.hora='';
            this.motivo='';
        },
        extractContent(s) {
            var span = document.createElement('span');
            span.innerHTML = s;
            try {
                return decodeURIComponent(escape(span.textContent)) || decodeURIComponent(escape(span.innerText));
            }
            catch (error) {
                console.log(error);
                return s;
            }
        },
    },
})
</script>
